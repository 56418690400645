import React from 'react'
import { CtaBar, LinkButton, LeshenPhoneCTA } from '@leshen/gatsby-theme-leshen'
import ShoppingCart from '../images/icons/shopping-cart.svg'

const SeoCtaBar = () => {
  return (
    <CtaBar
      backgroundColor="primary"
      textColor="white"
      sticky
      leftBlock={
        <LeshenPhoneCTA
          color="white"
          cobra="Hide CTA"
          hidePhoneNumberOnMobile
          mobileText="None"
        >
          Call Now
        </LeshenPhoneCTA>
      }
      middleBlock={
        <LinkButton
          color="white"
          mobileText="None"
          leftIcon={<img src={ShoppingCart} alt="shopping cart icon" />}
          to="https://www.sunfirematrix.com/app/consumer/clearlink/18572496/#/"
        >
          View Plans
        </LinkButton>
      }
    />
  )
}

export default SeoCtaBar
