/* eslint-disable no-else-return */
/* eslint-disable react/jsx-no-undef */
import React, { useState } from 'react'
import { LinkButton, Typography, useMapi } from '@leshen/gatsby-theme-leshen'
import { Phone } from 'emotion-icons/material'

const MultiStepForm = () => {
  const [step, setStep] = useState(1)
  const [hasMedicareMedicaid, setHasMedicareMedicaid] = useState(null)
  const { rotatedNumber } = useMapi()

  const handleYesNoClick = (value) => {
    if (step === 1) {
      setHasMedicareMedicaid(value)
      setStep(2)
    } else if (step === 2) {
      setStep(3)
    } else if (step === 3) {
      setStep(3)
    }
  }

  const renderStepContent = () => {
    switch (step) {
      case 1:
        return (
          <>
            <Typography variant="h1" color="light">
              Have Medicare & Medicaid? You May Qualify{' '}
            </Typography>
            <Typography variant="h4" color="light">
              Get additional health benefits, such as vision and hearing
              coverage, with low monthly plan premiums.{' '}
            </Typography>
            <div className="card bg-light text-align-left">
              <Typography variant="h4" color="dark">
                Do you have Medicare along with Medicaid?
              </Typography>
              <div className="buttons">
                <LinkButton onClick={() => handleYesNoClick(true)}>
                  Yes
                </LinkButton>
                <LinkButton onClick={() => handleYesNoClick(false)}>
                  No
                </LinkButton>
              </div>
            </div>
          </>
        )

      case 2:
        if (hasMedicareMedicaid) {
          return (
            <div className="card bg-light text-align-left">
              <Typography variant="h4" color="dark">
                Are you looking for extra help with healthcare costs?
              </Typography>
              <div className="buttons">
                <LinkButton onClick={() => handleYesNoClick(true)}>
                  Yes
                </LinkButton>
                <LinkButton
                  to="https://www.sunfirematrix.com/app/consumer/clearlink/18572496/"
                  target="_blank"
                >
                  No
                </LinkButton>
              </div>
            </div>
          )
        } else {
          return (
            <div className="card bg-light text-align-left">
              <Typography variant="h4" color="dark">
                If you don’t qualify for Medicaid, consider a Medicare Advantage
                plan, with plan premiums starting at $0/month.
              </Typography>
              <LinkButton
                to="https://www.sunfirematrix.com/app/consumer/clearlink/18572496/"
                target="_blank"
              >
                Browse Plans
              </LinkButton>
            </div>
          )
        }

      case 3:
        return (
          <>
            <div className="card bg-light text-align-left">
              <Typography variant="h4" color="dark">
                Speak with a licensed agent today to see if you qualify
              </Typography>
              <LinkButton to={`tel:${rotatedNumber}`} leftIcon={<Phone />}>
                Call {rotatedNumber}
              </LinkButton>
            </div>
            <Typography variant="h4" color="light">
              <br />
              OR…
            </Typography>
            <div className="card bg-light">
              <Typography variant="h4" color="dark">
                Schedule a call with a licensed agent at a time that works for
                you
              </Typography>
              <LinkButton to="/form">Schedule a Call</LinkButton>
            </div>
          </>
        )

      default:
        return null
    }
  }

  return <div>{renderStepContent()}</div>
}

export default MultiStepForm
