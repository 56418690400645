/* eslint-disable */
import React from 'react'
import { graphql } from 'gatsby'

import dsnpHero from '../../images/heroes/woman-looking-right-smiling.jpg'
import dsnpHeroMobile from '../../images/heroes/woman-looking-right-smiling-mobile.jpg'
import StepForm from '../../components/StepForm/index'

import { Phone } from 'emotion-icons/material'
import { StaticImage } from 'gatsby-plugin-image'
import SeoCtaBar from '../../components/SeoCtaBar'

import {
  Banner,
  Columns,
  Column,
  Hero,
  LinkButton,
  Stack,
  Typography,
  VariableContent,
  useMapi,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import footer from '../../components/FooterPPC'
import header from '../../components/HeaderPPC'

const Home = ({ data }) => {
  const { rotatedNumber } = useMapi()

  const brandyList = [
    'disclaimer-thirdpartymarketing',
    'disclaimer-footer1',
    'disclaimer-amd-medicareadvantagelp',
  ]

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(brandyList, data?.allBrandyDisclaimer?.edges),
        seo: {
          title:
            ' Aetna Medicare | Find the right plan for your individual coverage ',
          description: '',
          canonical: 'https://www.aetnamedicaredirect.com/',
          robots: 'follow,index',
        },
        path: '/lp/dsnp',
        promoCode: '88487',
        ringpool: 'MED',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://www.aetnamedicaredirect.com/',
          siteName: 'aetnamedicaredirect',
          alternateName: 'aetnamedicaredirect',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate:
      'Aetna Medicare | Find the right plan for your individual coverage ',
    defaultRobots: 'follow,index',
    main: (
      <>
        <div className="hero-content">
          <div className="wrapper">
            <Hero
              variant="full"
              className="leshen-hero reverse-content-desktop mobile-background-dark"
              image={
                <img
                  src={dsnpHero}
                  alt="elderly couple walking on the beach and smiling"
                />
              }
              mobileImage={
                <img
                  src={dsnpHeroMobile}
                  alt="elderly couple walking on the beach and smiling"
                />
              }
              mainContent={
                <>
                  <StepForm />
                </>
              }
            />
          </div>
        </div>

        <SeoCtaBar />

        <VariableContent
          alignMainContent="left"
          mainContent={
            <>
              <Typography variant="h2">
                Dual Eligible Special Needs Plan: what is it and who qualifies?
              </Typography>
              <Typography variant="body">
                A Dual Eligible Special Needs plan (D-SNP) is for those who
                qualify for both Medicare and Medicaid. If you’re living in a
                low-income household, you may qualify for Medicaid, which may
                allow you to receive affordable healthcare when you need it
                most. When you qualify for Medicare and Medicaid, a Dual
                Eligible Special Needs plan provides even more coverage,
                including hearing and vision, at lower monthly premiums. Each
                state determines which plans are available and what benefits are
                provided. D-SNP’s may include:
              </Typography>
            </>
          }
        >
          <Stack></Stack>
          <Columns>
            <Column className="card text-align-left">
              <StaticImage
                src="../../images/icons/purple-dollar.svg"
                alt="dollar icon"
                layout="fixed"
                width={100}
                height={100}
                className="column-icon"
              />
              <br />
              <Typography variant="h5" color="dark">
                $0 monthly premiums
              </Typography>
              <Typography variant="body">
                No monthly premiums mean more money in your pocket for the
                things that matter most.
              </Typography>
            </Column>
            <Column className="card text-align-left">
              <StaticImage
                src="../../images/icons/purple-glasses.svg"
                alt="glasses icon"
                layout="fixed"
                width={100}
                height={100}
                className="column-icon"
              />
              <br />
              <Typography variant="h5" color="dark">
                Vision, dental, and hearing benefits
              </Typography>
              <Typography variant="body">
                Take care of your eyes, teeth, and ears to experience life to
                the fullest.
              </Typography>
            </Column>
            <Column className="card text-align-left">
              <StaticImage
                src="../../images/icons/purple-pill.svg"
                alt="pill icon"
                layout="fixed"
                width={100}
                height={100}
                className="column-icon"
              />
              <br />
              <Typography variant="h5" color="dark">
                Part D coverage
              </Typography>
              <Typography variant="body">
                Part D can help cover your prescription drug costs.
              </Typography>
            </Column>
            <Column className="card text-align-left">
              <StaticImage
                src="../../images/icons/purple-yoga.svg"
                alt="yoga icon"
                layout="fixed"
                width={100}
                height={100}
                className="column-icon"
              />
              <br />
              <Typography variant="h5" color="dark">
                Gym memberships
              </Typography>
              <Typography variant="body">
                Plans with fitness benefits to help you stay active and healthy.
              </Typography>
            </Column>
          </Columns>
        </VariableContent>

        <VariableContent
          backgroundColor="lightGray"
          alignMainContent="left"
          mainContent={
            <>
              <Typography variant="h4">
                Talk to a licensed agent today to see if you qualify for a Dual
                Eligibility Special Needs plan.
              </Typography>
              <Typography variant="body">
                The Medicaid continuous enrollment provision is unwinding, and
                you may not be eligible for D-SNP anymore. Our experts are ready
                to help you explore other plan options that may be available in
                your area, such as Medicare Advantage plans with $0/month
                premiums.
              </Typography>
            </>
          }
        >
          <Banner
            backgroundColor="Primary"
            className="cta-banner column-desktop"
            mainContent={
              <>
                <Typography
                  variant="h5"
                  style={{ color: 'white', marginBottom: '5px' }}
                >
                  Call to see if you qualify today!
                </Typography>
                <Typography
                  variant="body"
                  style={{ color: 'white', marginBottom: '10px' }}
                >
                  TTY: 711, available 24/7
                </Typography>
                <LinkButton
                  variant="feature"
                  to={`tel:${rotatedNumber}`}
                  color="light"
                  className="margin-x-auto"
                  leftIcon={<Phone />}
                  requestId={require}
                >
                  {rotatedNumber}
                </LinkButton>
              </>
            }
          />
        </VariableContent>
      </>
    ),
  }

  return <Layout {...layoutProps} />
}

export default Home

export const query = graphql`
  query HomeQuery {
    allBrandyDisclaimer {
      edges {
        node {
          brandy_id
          text
          symbol
        }
      }
    }
  }
`
